import React, { FC, useEffect, useState } from "react";
import NewLayout from "@components/NewLayout";
import Image from "next/image";
import "keen-slider/keen-slider.min.css";
import { MdArrowOutward } from "react-icons/md";
import { IoChevronBackCircleOutline, IoChevronForwardCircleOutline } from "react-icons/io5";
import ClientLogos2 from "@components/ClientLogos2";
import YellowCTAhref from "@components/yellowCTAhref";

export const CASE_STUDIES = [
    {
        id: 1,
        bannerimg: "https://assets.gallabox.com/gb-home/qatar-insurance-company/header-image.png",
        tag: "Qatar Insurance Company",
        title: "How Qatar Insurance Company Doubled Lead Generation with Gallabox",
        link: "/case-study/qatar-insurance-company",
    },
    {
        id: 2,
        bannerimg: "https://assets.gallabox.com/gb-home/property2x/property2x-header.png",
        tag: "Real estate",
        title: "How Property2X Used Gallabox to Achieve a 300% Increase in Gross Profit",
        link: "/case-study/property2x",
    },
    {
        id: 3,
        bannerimg: "https://assets.gallabox.com/gb-home/hugg/hugg-header.png",
        tag: "D2C",
        title: "Maximizing Conversions and Streamlining Support : Hugg's Transformation",
        link: "/case-study/hugg",
    },
    {
        id: 4,
        bannerimg: "https://assets.gallabox.com/gb-home/go-ireland/goireland-header.png",
        tag: "Education",
        title: "How GoIreland Used Gallabox to Increase Conversions by 39%",
        link: "/case-study/goireland",
    },

    {
        id: 5,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/case-study-banner-1.png",
        tag: "Travel & Tourism",
        title: "LivingStone Stays Relocated Their Communications From IVR..",
        link: "/case-study/livingstone-stay",
    },
    {
        id: 6,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/case-study-banner-3.png",
        tag: "HR & Professional Service",
        title: "Jobstars India Deployed a Multilingual Chatbot to Handle 60%..",
        link: "/case-study/jobstars-india",
    },
    {
        id: 7,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/vcare/vcare-banner-img.png",
        tag: "Healthcare",
        title: "VCare increases their lead generation, conversion rate, and the quality of..",
        link: "/case-study/vcare",
    },
    {
        id: 8,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/numberwale/numberwale-banner-img.png",
        tag: "Telecom",
        title: "Numberwale increases their sales by nearly 25% with the help of Gallabox.",
        link: "/case-study/numberwale",
    },
    {
        id: 9,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/propleaf/propleaf-banner-img.png",
        tag: "Real estate",
        title: "PropLeaf Realty Tech increases their connect ratio with customers by 25%.",
        link: "/case-study/propleaf",
    },
    {
        id: 10,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/curioed/curioed-banner-img-new.png",
        tag: "Education",
        title: "CurioEd increases their engagement rate by 40%.",
        link: "/case-study/curioed",
    },
    {
        id: 11,
        bannerimg: "https://assets.gallabox.com/gb-home/case-study/case-study-banner-2.png",
        tag: "Retail, D2C",
        title: "Sattvic Stores Reduced RTO by 10% within a month of using Gallabox..",
        link: "/case-study/sattvic-store",
    },
];
export const CaseStudyHomePageInput = CASE_STUDIES.slice(0, 3);
interface CasestudyHerosection {
    title: string;
    description: string;
    imageUrl: string;
    externalLink: string;
}

interface SliderHerosectionProps {
    HerosectionData: CasestudyHerosection[];
}

export interface CasestudyCardProps {
    imageUrl: string;
    title: string;
    externalLink: string;
    sector: string;
}

const HerosectionData: CasestudyHerosection[] = [
    {
        title: "Gallabox powers Pickyourtrail's WhatsApp marketing success",
        description:
            "Discover how Pickyourtrail revolutionized their WhatsApp marketing campaigns and streamlined customer communication for travel using Gallabox's innovative solutions.",
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/pickyourtrail.png",
        externalLink: "/case-study/pickyourtrail",
    },
    {
        title: "Gallabox transforms Dubai Housekeeping's journey to service excellence on WhatsApp",
        description: "Discover how Dubai Housekeeping transformed their customer communication, scaled operations, and put convenience first using Gallabox's WhatsApp automation.",
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/dubai-housekeeping.png",
        externalLink: "/case-study/dubai-housekeeping",
    },
    {
        title: "Gallabox revolutionizes lead engagement for Footprint Real Estate",
        description:
            "Find out how Footprint Real Estate transformed their WhatsApp lead engagement and customer communication using Gallabox's innovative features, achieving exceptional growth in conversions and customer acquisition.",
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/footprint-real-estate.png",
        externalLink: "/case-study/footprint-real-estate",
    },
];

export const casestudyCards: CasestudyCardProps[] = [
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/pickyourtrail.png",
        title: "Gallabox powers Pickyourtrail's WhatsApp marketing success",
        sector: "Travel",
        externalLink: "/case-study/pickyourtrail",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/footprint-real-estate.png",
        title: "Gallabox revolutionizes lead engagement for Footprint Real Estate",
        sector: "Service",
        externalLink: "/case-study/footprint-real-estate",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/educenter.png",
        title: "How Educenter used WhatsApp to empower students and increased revenue by 40%",
        sector: "Education",
        externalLink: "/case-study/educenter",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/dubai-housekeeping.png",
        title: "Gallabox transforms Dubai Housekeeping's journey to service excellence on WhatsApp",
        sector: "Service",
        externalLink: "/case-study/dubai-housekeeping",
    },

    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/alrannan-admin-service.png",
        title: "How Alrannan Administrative Services automated sales with Gallabox",
        sector: "Service",
        externalLink: "/case-study/alrannan-administrative-services",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/itees-eye-hospital.png",
        title: "Gallabox improves patient experience at ITEES Eye Hospital on WhatsApp",
        sector: "Healthcare",
        externalLink: "/case-study/itees",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/goireland.png",
        title: "GoIreland's Success Story : Improved Transparency and Increased Conversions",
        sector: "Education",
        externalLink: "/case-study/goireland",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/qatar-insurance-company.png",
        title: "How Qatar Insurance Company doubled lead generation with Gallabox",
        sector: "Finance",
        externalLink: "/case-study/qatar-insurance-company",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/property-2x.png",
        title: "How Property2X used Gallabox to achieve a 300% increase in gross profit",
        sector: "Real Estate",
        externalLink: "/case-study/property2x",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/hugg.png",
        title: "How Hugg scaled conversions by 30% with Gallabox's WhatsApp automation",
        sector: "D2C",
        externalLink: "/case-study/hugg",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/living-stone.png",
        title: "How LivingStone Stays streamlined their sales, marketing, and support using Gallabox",
        sector: "Travel",
        externalLink: "/case-study/livingstone-stay",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/jobstars-india.png",
        title: "How Jobstars India hit 60% conversions using Gallabox's multilingual WhatsApp bot",
        sector: "HR/Recruitment",
        externalLink: "/case-study/jobstars-india",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/vcare.png",
        title: "VCare increases Facebook ad returns by 15% with Gallabox's Click-to-WhatsApp Ads",
        sector: "Healthcare",
        externalLink: "/case-study/vcare",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/telecom.png",
        title: "Numberwale increases their sales by nearly 25% with the help of Gallabox.",
        sector: "Telecom",
        externalLink: "/case-study/numberwale",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/properleaf.png",
        title: "How PropLeaf increased customer connect ratio by 25% with Gallabox's shared WhatsApp inbox",
        sector: "Real Estate",
        externalLink: "case-study/propleaf",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/curoid.png",
        title: "CurioEd increases open rates by 38% with Gallabox's WhatsApp Business API",
        sector: "Education",
        externalLink: "/case-study/curioed",
    },
    {
        imageUrl: "https://assets.gallabox.com/gb-home/case-study/%20sattvic-store.png",
        title: "How Sattvic Store acquired 2000+ customers with Gallabox's WhatsApp chatbots",
        sector: "D2C",
        externalLink: "/case-study/sattvic-store",
    },
];

const CaseStudyPages: FC = () => {
    return (
        <NewLayout
            title="Customer Case Studies | Gallabox"
            description="See how our customers of all sizes have grown using Gallabox Shared Inbox, WhatsApp No-Code Chatbot and WhatsApp Broadcasts  to increase their lead generation and conversion rate."
            canonical="https://gallabox.com/case-study"
            customFooterTop={true}
        >
            <section
                className="md:pt-40 pb-20 pt-10"
                style={{
                    background: "linear-gradient(104deg, #F0F8FF 39.74%, #C3D3FF 84.28%, #3868FD 102.96%)",
                }}
            >
                <SliderHerosection HerosectionData={HerosectionData} />
            </section>

            <section className="pt-20 pb-20">
                <ClientLogos2
                    heading={
                        <h3 className="text-3xl font-normal text-gray-700 text-center mb-8">
                            Trusted by <span className="font-bold">2,000+ businesses</span>
                        </h3>
                    }
                />
            </section>

            <section>
                <h3 className="text-gray-900 max-w-6xl mx-auto mb-10 mt-5 text-center md:text-left">Check out other customer stories</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 max-w-6xl mx-auto gap-y-10">
                    {casestudyCards?.map((data, index) => (
                        <CasestudyCard key={index} {...data} />
                    ))}
                </div>
            </section>

            <section className="flex md:flex-row flex-col max-w-6xl mx-auto md:justify-between mt-24 mb-20 px-4 md:px-0">
                <div className="mb-10 md:mb-4">
                    <p className="text-blue-500 font-semibold">AWARDS</p>
                    <h3 className="text-3xl">
                        Recognized by <br></br> industry leaders
                    </h3>
                </div>
                <img src="/casestudybatch.png" alt="casestudy-batch" />
            </section>

            <YellowCTAhref
                buttonText="Signup for free"
                secondaryButtonText="Book a Demo"
                link2="https://gallabox.com/demo"
                customText={"Transform your goals into wins with Gallabox"}
                cardStyles={{ cardColor: "#E2E8F0", cardTextColor: "black" }}
            />
        </NewLayout>
    );
};

export default CaseStudyPages;

const SliderHerosection: React.FC<SliderHerosectionProps> = ({ HerosectionData }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === HerosectionData.length - 1 ? 0 : prevIndex + 1));
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? HerosectionData.length - 1 : prevIndex - 1));
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 3000);
        return () => clearInterval(interval);
    }, [currentIndex]);

    return (
        <section className="slider-container relative max-w-6xl mx-auto" style={{ minHeight: "500px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div className="keen-slider relative overflow-hidden" style={{ height: "100%" }}>
                {HerosectionData.map((data, index) => (
                    <div
                        key={index}
                        className={`slider-card flex md:flex-row flex-col justify-between items-center transition-opacity duration-500 ${
                            currentIndex === index ? "opacity-100 shadow-lg" : "opacity-0 absolute pointer-events-none"
                        }`}
                        style={{ minHeight: "500px", alignItems: "center" }}
                    >
                        <div className="flex flex-col lg:w-1/2 lg:mx-0 w-fit mx-2 self-start">
                            <div className="flex flex-col gap-6">
                                <h1 className="md:text-5xl font-bold mb-2 text-3xl lineheight">{data.title}</h1>
                                <p className="text-gray-600 md:text-left text-center">{data.description}</p>
                                <a
                                    className="md:ml-0 px-6 py-4 bg-blue-600 text-white flex items-center gap-x-2 font-semibold mt-4 mx-auto hover:no-underline rounded"
                                    href={data.externalLink}
                                >
                                    Read the story
                                    <span>
                                        <MdArrowOutward size={20} />
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="px-2 md:px-0 pt-4 md:pt-0">
                            <Image src={data.imageUrl} alt={data.title} width={500} height={500} className="rounded-lg shadow-md" />
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex w-2/3 mt-5 md:mt-0 md:ml-auto gap-x-4">
                <div className="ml-auto cursor-pointer" onClick={prevSlide}>
                    <IoChevronBackCircleOutline size={50} className="text-gray-700 hover:text-gray-900 self-center" />
                </div>
                <div className="cursor-pointer" onClick={nextSlide}>
                    <IoChevronForwardCircleOutline size={50} className="text-gray-700 hover:text-gray-900" />
                </div>
            </div>
            <style jsx>{`
                .lineheight {
                    line-height: 40px;
                }

                @media (min-width: 768px) {
                    .lineheight {
                        line-height: 60px;
                    }
                }
            `}</style>
        </section>
    );
};

export const CasestudyCard: React.FC<CasestudyCardProps> = ({ imageUrl, title, externalLink, sector }) => {
    return (
        <div className="flex flex-col justify-between  rounded  h-full hover:shadow-xl border border-solid border-gray-200 mx-3">
            <Image src={imageUrl} width={397} height={332} alt={sector} style={{ borderRadius: "4px 4px 0 0" }} />

            <div className="flex flex-col flex-grow p-6 gap-3">
                <p className="text-gray-600">{sector.toUpperCase()}</p>
                <p className="text-xl text-gray-900 font-medium mb-4">{title}</p>
            </div>

            <div className="mt-auto p-6">
                <a
                    href={externalLink}
                    target="_blank"
                    rel="noreferrer"
                    className="mr-auto px-6 py-3 bg-black
                 text-white flex items-center gap-3 w-fit hover:shadow-md hover:no-underline  rounded hover:bg-blue-600"
                >
                    Read the story
                    <MdArrowOutward size={20} />
                </a>
            </div>
        </div>
    );
};
