import { isJSXElement } from "@utils/common";
import React, { FC } from "react";

export interface CardStylesProps {
    cardColor?: string;
    cardTextColor?: string;
    buttonTextColor?: string;
    buttonBackgroundColor?: string;
    buttonHoverColor?: string;
    secondaryButtonTextColor?: string;
    secondaryButtonBackgroundColor?: string;
    secondaryButtonBorderColor?: string;
}

interface YellowCTAHrefProps {
    customText?: JSX.Element | string;
    link1?: string;
    link2?: string;
    buttonText?: string;
    secondaryButtonText?: string;
    text2?: string;
    cardStyles?: CardStylesProps;
    target?: string;
}

const YellowCTAhref: FC<YellowCTAHrefProps> = (props) => {
    const {
        customText = "Get a demo customized to your institution workflow",
        link1 = "https://app.gallabox.com/signup",
        link2,
        buttonText = "Start your 7-day trial",
        secondaryButtonText = "Schedule a Demo",
        text2,
        cardStyles = {},
        target = "_blank",
    } = props;

    const {
        cardColor = " #feee95",
        cardTextColor = "text-yellow-800",
        buttonTextColor = "text-white",
        buttonBackgroundColor = "bg-blue-500",
        buttonHoverColor = "bg-blue-600",
        secondaryButtonBackgroundColor = "bg-white",
        secondaryButtonTextColor = "text-blue-600",
        secondaryButtonBorderColor = "border-blue-600",
    } = cardStyles;

    return (
        <section className="py-10 lg:px-0 px-3">
            <div className={"max-w-6xl mx-auto  text-center rounded flex flex-col gap-4 lg:py-10 px-5 py-8"} style={{ backgroundColor: `${cardColor}` }}>
                {isJSXElement(customText) ? customText : <div className={`text-2xl md:text-3xl font-bold mb-4 relative inline-block ${cardTextColor}`}>{customText}</div>}

                <div className="flex justify-center md:flex-row text-lg px-6 gap-x-3 md:gap-x-6">
                    <a
                        href={link1}
                        target={target}
                        rel="noreferrer"
                        className={`px-4 py-2 lg:px-10 text-base font-medium ${buttonTextColor} rounded-md hover:no-underline focus:outline-none hover:shadow-lg ${buttonBackgroundColor} hover:${buttonHoverColor} transition duration-300 ease-in-out`}
                    >
                        {buttonText}
                    </a>
                    {link2 && (
                        <a
                            href={link2}
                            target="_blank"
                            rel="noreferrer"
                            className={`px-4 py-2 text-base font-medium rounded-md hover:no-underline focus:outline-none ${secondaryButtonTextColor} hover:shadow-2xl ${secondaryButtonBackgroundColor} transition duration-300 ease-in-out   border border-solid ${secondaryButtonBorderColor} `}
                        >
                            {secondaryButtonText}
                        </a>
                    )}
                </div>
                <div className={`text-sm ${cardTextColor}`}>{text2}</div>
            </div>
        </section>
    );
};

export default YellowCTAhref;
